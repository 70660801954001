var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TopMenu'),_c('v-dialog',{attrs:{"max-width":"350","persistent":""},model:{value:(_vm.showDialogSelectionUserType),callback:function ($$v) {_vm.showDialogSelectionUserType=$$v},expression:"showDialogSelectionUserType"}},[_c('v-card',[_c('v-card-title',[_vm._v("Tipo de Persona")]),_c('div',{staticClass:"pl-5 pr-5 pb-5"},[_c('v-radio-group',{model:{value:(_vm.formFields.userType),callback:function ($$v) {_vm.$set(_vm.formFields, "userType", $$v)},expression:"formFields.userType"}},[_c('v-radio',{attrs:{"label":"Persona Natural","value":_vm.allConstants.security.userTypes.person}}),_c('v-radio',{attrs:{"label":"Persona Jurídica","value":_vm.allConstants.security.userTypes.business}})],1),_c('v-btn',{staticClass:"mr-3",on:{"click":_vm.restart}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.endCollectUserType}},[_vm._v("Continuar")])],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-card',{staticClass:"pa-2",attrs:{"elevation":"1","tile":""}},[_c('div',{staticClass:"text-h4 mb-10"},[_vm._v(" "+_vm._s(_vm.form.processing ? "Creando el reclamo ..." : "Creación de reclamo")+" ")]),(
          _vm.currentClaimInCreation.currentStep ==
            _vm.allConstants.claims.creationSteps.collectBasicData
        )?_c('collectorBasicData',{attrs:{"userType":_vm.currentClaimInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":true,"askForPassword":false,"stopIfAccountExist":false,"preFillIfAccountExist":true,"includePhoneVerification":false,"preloadData":_vm.currentClaimInCreation.collectedBasicData},on:{"accountExist":_vm.processAccountExist,"enter":_vm.endCollectBasicData}}):_vm._e(),(
          _vm.currentClaimInCreation.currentStep ==
            _vm.allConstants.claims.creationSteps.collectAddressData
        )?_c('collectorAddressData',{attrs:{"userType":_vm.currentClaimInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":false,"preloadData":_vm.currentClaimInCreation.collectedAddressData},on:{"enter":_vm.endCollectAddressData,"back":_vm.currentClaimInCreationGoBackward}}):_vm._e(),(
          _vm.currentClaimInCreation.currentStep ==
            _vm.allConstants.claims.creationSteps.collectInstitutionData
        )?_c('collectorInstitutionData',{attrs:{"userType":_vm.currentClaimInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":false,"preloadData":_vm.currentClaimInCreation.collectedInstitutionData},on:{"enter":_vm.endCollectInstitutionData,"back":_vm.currentClaimInCreationGoBackward}}):_vm._e(),(
          _vm.currentClaimInCreation.currentStep ==
            _vm.allConstants.claims.creationSteps.collectProducts
        )?_c('collectorProductsData',{attrs:{"userType":_vm.currentClaimInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":false,"preloadData":_vm.currentClaimInCreation.collectedProductData},on:{"enter":_vm.endCollectProductData,"back":_vm.currentClaimInCreationGoBackward}}):_vm._e(),(
          _vm.currentClaimInCreation.currentStep ==
            _vm.allConstants.claims.creationSteps.collectRequestData
        )?_c('collectorRequestData',{attrs:{"userType":_vm.currentClaimInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":false,"preloadData":_vm.currentClaimInCreation.collectedRequestData},on:{"enter":_vm.endCollectRequestData,"back":_vm.currentClaimInCreationGoBackward}}):_vm._e(),(
          _vm.currentClaimInCreation.currentStep ==
            _vm.allConstants.claims.creationSteps.collectDocumentsData
        )?_c('collectorDocumentsData',{attrs:{"userType":_vm.currentClaimInCreation.userType,"showMainButton":true,"showBackButton":true,"disableBackButton":false,"includeTermsAndConditions":true},on:{"back":_vm.currentClaimInCreationGoBackward,"enter":_vm.endCollectDocumentsData,"abort":_vm.restart}}):_vm._e(),(_vm.form.processing)?_c('v-progress-linear',{staticClass:"mt-10 mb-10",attrs:{"indeterminate":"","color":"cyan"}}):_vm._e(),(_vm.showResultClaimCreation)?_c('div',{staticClass:"text-h6"},[_vm._v(" EL RECLAMO HA SIDO ENVIADO CORRECTAMENTE CON EL FOLIO N° "+_vm._s(_vm.claimCreatedNumber)+" ")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }