<template>
  <div>
    Tu sesión finaliza en
    <span>{{ minutes }}</span>
    <span>:</span>
    <span>{{ seconds }}</span>
  </div>
</template>

<script>
import currentUserMixin from "@/mixins/currentUser";
export default {
  mixins: [currentUserMixin],
  data() {
    return {
      timer: null,
      totalTime: 15 * 60,
    };
  },
  methods: {
    startTimer() {
      const fechaEnMiliseg = Date.now();
      const fechaExpires = new Date(this.currentUserInfo.expires);

      const diferencia = Math.abs(fechaExpires - fechaEnMiliseg);
      this.totalTime = Math.floor(parseInt(diferencia) / 1000);

      this.timer = setInterval(() => this.countdown(), 1000); //1000ms = 1 second
    },
    countdown() {
      const fechaEnMiliseg = Date.now();

      if (
        this.currentUserInfo == undefined ||
        this.currentUserInfo == null ||
        this.currentUserInfo.expires == undefined ||
        this.currentUserInfo.expires == null
      )
        return;

      let fechaExpires = null;
      try {
        fechaExpires = new Date(this.currentUserInfo.expires);
      } catch (error) {
        console.log(error);
        return;
      }
      if (fechaExpires == undefined || fechaExpires == null) return;

      const diferencia = Math.abs(fechaExpires - fechaEnMiliseg);
      this.totalTime = Math.floor(parseInt(diferencia) / 1000);

      if (this.currentUserIsAuthenticated) {
        if (this.totalTime == 60)
          this.$store.dispatch(
            "notifications/addError",
            "Su sesión finalizará en 1 minuto"
          );

        if (this.totalTime == 180)
          this.$store.dispatch(
            "notifications/addInfo",
            "Su sesión finalizará en 3 minutos"
          );

        if (this.totalTime == 300)
          this.$store.dispatch(
            "notifications/addInfo",
            "Su sesión finalizará en 5 minutos"
          );
      }
    },
    padTime(time) {
      if (time < 0) return "00";
      return (time < 10 ? "0" : "") + time;
    },
  },
  computed: {
    minutes: function() {
      const minutes = Math.floor(this.totalTime / 60);
      return this.padTime(minutes);
    },
    seconds: function() {
      const seconds = this.totalTime - this.minutes * 60;
      return this.padTime(seconds);
    },
  },
  beforeMount() {
    this.startTimer();
  },
};
</script>

<style lang="scss" scoped></style>
