<template>
  <div>
    <v-list dense>
      <v-list-item>
        <v-list-item-avatar>
          <v-icon x-large color="primary">mdi-account-circle</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title class="text-h6">
            {{ currentUserInfo.visiblename }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ currentUserRoleName }}
          </v-list-item-subtitle>
          <v-list-item-subtitle
            >Su sesión termina el
            {{ currentUserInfo.expires | formatDateShort }} a las
            {{
              currentUserInfo.expires | formatTimeShort
            }}</v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider
        class="pa-0 ma-0"
        v-if="
          currentUserInfo.roles[0] !=
            allConstants.security.userRolesCodes.ddcDefender
        "
      />

      <v-list-item
        link
        @click="gotoCreateClaim"
        v-if="
          currentUserInfo.roles[0] !=
            allConstants.security.userRolesCodes.ddcDefender
        "
      >
        <v-list-item-content>
          <v-list-item-title>Crear Reclamos</v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon color="primary">mdi-text-box-plus</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider class="pa-0 ma-0" />

      <v-list-group no-action>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Reclamos</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="claim in claims"
          :key="claim.title"
          link
          dense
          @click="goToViewMenuLeft(claim.goTo)"
        >
          <v-list-item-title v-text="claim.title"></v-list-item-title>
          <v-list-item-action class="caption">
            <v-spacer />{{ claim.count }}
          </v-list-item-action>
        </v-list-item>
      </v-list-group>

      <v-divider class="pa-0 ma-0" />

      <v-list-group no-action v-if="queries.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Consultas</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="queries in queries"
          :key="queries.title"
          link
          dense
          @click="goToViewMenuLeft(queries.goTo)"
        >
          <v-list-item-title v-text="queries.title"></v-list-item-title>
          <v-list-item-action class="caption">
            <v-spacer />{{ queries.count }}
          </v-list-item-action>
        </v-list-item>
      </v-list-group>
      <v-divider class="pa-0 ma-0" v-if="informes.length > 0" />

      <v-list-group no-action v-if="informes.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Informes</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="informe in informes"
          :key="informe.code"
          link
          @click="goToViewMenuLeft(informe.goTo)"
        >
          <v-list-item-title
            v-text="informe.title"
            class="text-left"
          ></v-list-item-title>

          <v-list-item-icon>
            <v-icon :color="informe.iconColor" v-text="informe.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-divider class="pa-0 ma-0" v-if="informesSla.length > 0" />

      <v-list-group no-action v-if="informesSla.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Informes SLA</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="informe in informesSla"
          :key="informe.code"
          link
          @click="goToViewMenuLeft(informe.goTo)"
        >
          <v-list-item-title
            v-text="informe.title"
            class="text-left"
          ></v-list-item-title>

          <v-list-item-icon>
            <v-icon :color="informe.iconColor" v-text="informe.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-divider class="pa-0 ma-0" v-if="usuarios.length > 0" />

      <v-list-group no-action v-if="usuarios.length > 0">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>Usuarios sistema</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="menu in usuarios"
          :key="menu.code"
          link
          @click="goToViewMenuLeft(menu.goTo)"
        >
          <v-list-item-title
            v-text="menu.title"
            class="text-left"
          ></v-list-item-title>

          <v-list-item-icon>
            <v-icon :color="menu.iconColor" v-text="menu.icon"></v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-group>

      <v-divider class="pa-0 ma-0" />

      <v-list-item link @click="gotoView('logout')">
        <v-list-item-content>
          <v-list-item-title>Salir</v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon color="primary">mdi-home-export-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>

      <v-divider class="pa-0 ma-0" />

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title><SessionTimer /></v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-icon color="orange">mdi-clock-time-two-outline</v-icon>
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import parametersMixin from "@/mixins/parameters";
import currentUserMixin from "@/mixins/currentUser";
import currentDashboardMixin from "@/mixins/currentDashboard";

import SessionTimer from "@/components/WFSite/SessionTimer";

export default {
  mixins: [
    baseViewMixin,
    parametersMixin,
    currentUserMixin,
    currentDashboardMixin,
  ],
  components: {
    SessionTimer,
  },
  computed: {
    claims() {
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        this.ddcExecutive.forEach((m) => {
          if (m.code == "claimsToAdmit")
            m.count = this.currentDashboard.ddcExecutive.claimsToAdmit;

          if (m.code == "claimsPendingAssignment")
            m.count = this.currentDashboard.ddcExecutive.claimsPendingAssignment;

          if (m.code == "claimsSendPapers")
            m.count = this.currentDashboard.ddcExecutive.claimsSendPapers;

          if (m.code == "claimsPendingClient")
            m.count = this.currentDashboard.ddcExecutive.claimsPendingClient;

          if (m.code == "claimsPendingBank")
            m.count = this.currentDashboard.ddcExecutive.claimsPendingBank;

          if (m.code == "claimsPendingDefender")
            m.count = this.currentDashboard.ddcExecutive.claimsPendingDefender;

          if (m.code == "claimsClosed")
            m.count = this.$n(this.currentDashboard.ddcExecutive.claimsClosed);
        });

        return this.ddcExecutive;
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcDefender
      ) {
        this.ddcDefender.forEach((m) => {
          if (m.code == "claimsAssigned")
            m.count = this.currentDashboard.ddcDefender.claimsAssigned;

          if (m.code == "claimsNotCompatible")
            m.count = this.currentDashboard.ddcDefender.claimsNotCompatible;

          if (m.code == "claimsSendPapers")
            m.count = this.currentDashboard.ddcDefender.claimsSendPapers;

          if (m.code == "claimsPendingClient")
            m.count = this.currentDashboard.ddcDefender.claimsPendingClient;

          if (m.code == "claimsPendingBank")
            m.count = this.currentDashboard.ddcDefender.claimsPendingBank;

          if (m.code == "claimsClosed")
            m.count = this.$n(this.currentDashboard.ddcDefender.claimsClosed);
        });

        return this.ddcDefender;
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.bankAdministrator
      ) {
        this.bankAdministrator.forEach((m) => {
          if (m.code == "claimsCreated")
            m.count = this.currentDashboard.bankAdministrator.claimsCreated;

          if (m.code == "claimsForAnswer")
            m.count = this.currentDashboard.bankAdministrator.claimsForAnswer;

          if (m.code == "claimsInExtension")
            m.count = this.currentDashboard.bankAdministrator.claimsInExtension;

          if (m.code == "claimsSendPapers")
            m.count = this.currentDashboard.bankAdministrator.claimsSendPapers;

          if (m.code == "claimsPendingClient")
            m.count = this.currentDashboard.bankAdministrator.claimsPendingClient;

          if (m.code == "claimsPendingDDC")
            m.count = this.currentDashboard.bankAdministrator.claimsPendingDDC;

          if (m.code == "claimsPendingDefender")
            m.count = this.currentDashboard.bankAdministrator.claimsPendingDefender;

          if (m.code == "claimsClosed")
            m.count = this.$n(
              this.currentDashboard.bankAdministrator.claimsClosed
            );
        });

        return this.bankAdministrator;
      }

      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.bankExecutive
      ) {
        this.bankExecutive.forEach((m) => {
          if (m.code == "claimsCreated")
            m.count = this.currentDashboard.bankExecutive.claimsCreated;

          if (m.code == "claimsPendingResponse")
            m.count = this.currentDashboard.bankExecutive.claimsPendingResponse;

          if (m.code == "claimsInExtension")
            m.count = this.currentDashboard.bankExecutive.claimsInExtension;

          if (m.code == "claimsSendPapers")
            m.count = this.currentDashboard.bankExecutive.claimsSendPapers;

          if (m.code == "claimsPendingClient")
            m.count = this.currentDashboard.bankExecutive.claimsPendingClient;

          if (m.code == "claimsPendingDDC")
            m.count = this.currentDashboard.bankExecutive.claimsPendingDDC;

          if (m.code == "claimsPendingDefender")
            m.count = this.currentDashboard.bankExecutive.claimsPendingDefender;

          if (m.code == "claimsClosed")
            m.count = this.$n(this.currentDashboard.bankExecutive.claimsClosed);
        });

        return this.bankExecutive;
      }

      return [];
    },
    queries() {
      if (
        this.currentUserInfo.roles[0] ==
        this.allConstants.security.userRolesCodes.ddcExecutive
      ) {
        this.ddcExecutiveQuery.forEach((m) => {
          if (m.code == "queriesPending")
            m.count = this.$n(
              this.currentDashboard.ddcExecutive.queriesPending
            );

          if (m.code == "queriesClosed")
            m.count = this.$n(this.currentDashboard.ddcExecutive.queriesClosed);
        });

        return this.ddcExecutiveQuery;
      }

      return [];
    },
    informes() {
      switch (this.currentUserInfo.roles[0]) {
        case this.allConstants.security.userRolesCodes.ddcExecutive:
          return this.ddcExecutiveInformes;
        case this.allConstants.security.userRolesCodes.ddcDefender:
          return this.ddcDefenderInformes;
        case this.allConstants.security.userRolesCodes.bankAdministrator:
          return this.bankAdministratorInformes;
        case this.allConstants.security.userRolesCodes.bankExecutive:
          return this.bankExecutiveInformes;
        default:
          break;
      }

      return [];
    },
    informesSla() {
      switch (this.currentUserInfo.roles[0]) {
        case this.allConstants.security.userRolesCodes.ddcExecutive:
          return this.ddcExecutiveInformesSla;
        case this.allConstants.security.userRolesCodes.ddcDefender:
          return this.ddcDefenderInformesSla;
        case this.allConstants.security.userRolesCodes.bankAdministrator:
          return this.bankAdministratorInformesSla;
        case this.allConstants.security.userRolesCodes.bankExecutive:
          return this.bankExecutiveInformesSla;
        default:
          break;
      }

      return [];
    },
    usuarios() {
      switch (this.currentUserInfo.roles[0]) {
        case this.allConstants.security.userRolesCodes.ddcExecutive:
          return this.ddcExecutiveUsuarios;
        case this.allConstants.security.userRolesCodes.ddcDefender:
          return [];
        case this.allConstants.security.userRolesCodes.bankAdministrator:
          return [];
        case this.allConstants.security.userRolesCodes.bankExecutive:
          return [];
        default:
          break;
      }

      return [];
    },
  },
  data() {
    return {
      ddcExecutive: [
        {
          code: "claimsToAdmit",
          title: "Por revisar",
          count: 0,
          goTo: "ddcExecutive-claimsToAdmit",
        },
        {
          code: "claimsPendingAssignment",
          title: "Por asignar",
          count: 0,
          goTo: "ddcExecutive-claimsPendingAssignment",
        },
        {
          code: "claimsSendPapers",
          title: "Antecedentes adicionales pendientes",
          count: 0,
          goTo: "ddcExecutive-claimsSendPapers",
        },
        {
          code: "claimsPendingClient",
          title: "Pendiente cliente",
          count: 0,
          goTo: "ddcExecutive-claimsPendingClient",
        },
        {
          code: "claimsPendingBank",
          title: "Pendiente banco",
          count: 0,
          goTo: "ddcExecutive-claimsPendingBank",
        },
        {
          code: "claimsPendingDefender",
          title: "Pendiente defensor",
          count: 0,
          goTo: "ddcExecutive-claimsPendingDefender",
        },
        {
          code: "claimsClosed",
          title: "Cerrados",
          count: 0,
          goTo: "ddcExecutive-claimsClosed",
        },
      ],
      ddcExecutiveQuery: [
        {
          code: "queriesPending",
          title: "Por Responder",
          count: 0,
          goTo: "ddcExecutive-queriesPending",
        },
        {
          code: "queriesClosed",
          title: "Cerradas",
          count: 0,
          goTo: "ddcExecutive-queriesClosed",
        },
      ],
      ddcExecutiveInformes: [
        {
          code: "claimsToAdmit",
          title: "Consulta general",
          goTo: "ddcExecutive-claimReport",
          icon: "mdi-file-excel-box",
          iconColor: "teal",
        },

        {
          code: "finalizados",
          title: "Reclamos finalizados",
          goTo: "ddcExecutive-report-claims-closed",
          icon: "mdi-lock-check",
          iconColor: "pink",
        },

        {
          code: "ParticipacionPorcentual",
          title: "Participación porcentual",
          goTo: "ddcExecutive-report-claims-closed-percentage",
          icon: "mdi-chart-pie",
          iconColor: "purple",
        },
        {
          code: "ReclamosIngresadosXBanco",
          title: "Reclamos ingresados por banco",
          goTo: "ddcExecutive-report-claims-for-bank",
          icon: "mdi-bank-transfer-in",
          iconColor: "indigo",
        },
        {
          code: "ReclamosDeAcuerdoConSuTipologia",
          title: "Reclamos de acuerdo con su tipologia",
          goTo: "ddcExecutive-report-claims-typologies",
          icon: "mdi-sort-variant",
          iconColor: "blue",
        },
        {
          code: "ReclamosTramitadosYRechazados",
          title: "Reclamos tramitados y rechazados",
          goTo: "ddcExecutive-report-claims-all-state",
          icon: "mdi-bus-stop",
          iconColor: "lime",
        },
        {
          code: "DetalleReclamosTramitadosXDefensor",
          title: "Detalle de reclamos tramitados por defensor",
          goTo: "ddcExecutive-report-claims-defenders",
          icon: "mdi-account-details",
          iconColor: "red",
        },
      ],
      ddcExecutiveInformesSla: [],
      ddcExecutiveInformesSlaDev: [
        {
          code: "agendarInformeSla",
          title: "Agendar informe",
          goTo: "construction",
          icon: "mdi-calendar-arrow-right",
          iconColor: "deep-orange",
        },
        {
          code: "informeSlaConsultaAgendamiento",
          title: "Consultar informes agendados",
          goTo: "construction",
          icon: "mdi-file-search-outline",
          iconColor: "brown",
        },
      ],
      ddcExecutiveUsuarios: [],
      ddcExecutiveUsuariosDev: [
        {
          code: "usuariosCrear",
          title: "Crear usuario workflow",
          goTo: "construction",
          icon: "mdi-account-plus",
          iconColor: "green",
        },
        {
          code: "usuariosReclamantes",
          title: "Consultar usuarios reclamantes",
          goTo: "construction",
          icon: "mdi-clipboard-account",
          iconColor: "red accent-4",
        },
        {
          code: "usuariosEjecutivos",
          title: "Consultar usuarios ejecutivos DDC",
          goTo: "construction",
          icon: "mdi-account-tie",
          iconColor: "light-blue",
        },
        {
          code: "usuariosDefensores",
          title: "Consultar usuarios defensores",
          goTo: "construction",
          icon: "mdi-shield-account",
          iconColor: "light-green",
        },
        {
          code: "usuariosBanco",
          title: "Consultar usuarios bancos",
          goTo: "construction",
          icon: "mdi-bank",
          iconColor: "blue-grey",
        },
      ],
      ddcDefender: [
        {
          code: "claimsAssigned",
          title: "Pendiente defensor",
          count: 0,
          goTo: "ddcDefender-claimsAssigned",
        },
        {
          code: "claimsNotCompatible",
          title: "Incompatibles",
          count: 0,
          goTo: "ddcDefender-claimsNotCompatible",
        },
        {
          code: "claimsSendPapers",
          title: "Antecedentes adicionales pendientes",
          count: 0,
          goTo: "ddcDefender-claimsSendPapers",
        },
        {
          code: "claimsPendingClient",
          title: "Pendiente cliente",
          count: 0,
          goTo: "ddcDefender-claimsPendingClient",
        },
        {
          code: "claimsPendingBank",
          title: "Pendiente banco",
          count: 0,
          goTo: "ddcDefender-claimsPendingBank",
        },
        {
          code: "claimsClosed",
          title: "Cerrados",
          count: 0,
          goTo: "ddcDefender-claimsClosed",
        },
      ],
      ddcDefenderInformes: [],
      ddcDefenderInformesSla: [],
      bankAdministrator: [
        {
          code: "claimsCreated",
          title: "Ingresados",
          count: 0,
          goTo: "bankAdministrator-claimsCreated",
        },
        {
          code: "claimsForAnswer",
          title: "Por responder",
          count: 0,
          goTo: "bankAdministrator-claimsForAnswer",
        },
        {
          code: "claimsInExtension",
          title: "Prorrogables",
          count: 0,
          goTo: "bankAdministrator-claimsInExtension",
        },
        {
          code: "claimsSendPapers",
          title: "Entregar antecedentes adicionales",
          count: 0,
          goTo: "bankAdministrator-claimsSendPapers",
        },
        {
          code: "claimsPendingClient",
          title: "Pendiente cliente",
          count: 0,
          goTo: "bankAdministrator-claimsPendingClient",
        },
        {
          code: "claimsPendingDDC",
          title: "Pendiente ejecutivo DDC",
          count: 0,
          goTo: "bankAdministrator-claimsPendingDDC",
        },
        {
          code: "claimsPendingDefender",
          title: "Pendiente defensor",
          count: 0,
          goTo: "bankAdministrator-claimsPendingDefender",
        },
        {
          code: "claimsClosed",
          title: "Cerrados",
          count: 0,
          goTo: "bankAdministrator-claimsClosed",
        },
      ],
      bankAdministratorInformes: [
        {
          code: "claimsToAdmit",
          title: "Consulta general",
          goTo: "bankAdministrator-claimReport",
          icon: "mdi-file-excel-box",
          iconColor: "teal",
        },
      ],
      bankAdministratorInformesSla: [],
      bankExecutive: [
        {
          code: "claimsCreated",
          title: "Ingresados",
          count: 0,
          goTo: "bankExecutive-claimsCreated",
        },
        {
          code: "claimsPendingResponse",
          title: "Por responder",
          count: 0,
          goTo: "bankExecutive-claimsPendingResponse",
        },
        {
          code: "claimsInExtension",
          title: "Prorrogables",
          count: 0,
          goTo: "bankExecutive-claimsInExtension",
        },
        {
          code: "claimsSendPapers",
          title: "Entregar antecedentes adicionales",
          count: 0,
          goTo: "bankExecutive-claimsSendPapers",
        },
        {
          code: "claimsPendingClient",
          title: "Pendiente cliente",
          count: 0,
          goTo: "bankExecutive-claimsPendingClient",
        },
        {
          code: "claimsPendingDDC",
          title: "Pendiente ejecutivo DDC",
          count: 0,
          goTo: "bankExecutive-claimsPendingDDC",
        },
        {
          code: "claimsPendingDefender",
          title: "Pendiente defensor",
          count: 0,
          goTo: "bankExecutive-claimsPendingDefender",
        },
        {
          code: "claimsClosed",
          title: "Cerrados",
          count: 0,
          goTo: "bankExecutive-claimsClosed",
        },
      ],
      bankExecutiveInformes: [],
      bankExecutiveInformesSla: [],
    };
  },
  methods: {
    goToViewMenuLeft(code) {
      this.loadDashboard();
      this.gotoView(code);
    },
    gotoCreateClaim() {
      this.gotoView("claimCreationFlowInWf");
    },
    gotoReportClaim() {
      this.gotoView("ddcExecutive-claimReport");
    },
  },
};
</script>

<style lang="scss" scoped></style>
