<template>
  <div>
    <v-app-bar fixed app color="blue darken-4" dense dark>
      <v-app-bar-nav-icon @click.stop="menu"></v-app-bar-nav-icon>
      <v-btn icon @click="gotoView('dashboard')"
        ><v-icon>mdi-view-dashboard</v-icon></v-btn
      >

      <v-toolbar-title class="d-none d-sm-flex"
        >Workflow Gestión de Reclamos DDC</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              {{ currentUserInfo.name }} / {{ currentUserRoleName }}
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="gotoView('editprofile')">
              <v-list-item-title
                ><v-icon class="mr-3">mdi-account</v-icon>
                Perfil</v-list-item-title
              >
            </v-list-item>
            <v-list-item @click="gotoView('logout')">
              <v-list-item-title
                ><v-icon class="mr-3">mdi-home-export-outline</v-icon>
                Salir</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>

        <div class="caption">
          <SessionTimer />
        </div>
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      width="400"
      enable-resize-watcher
    >
      <LeftMenuNew />
    </v-navigation-drawer>
  </div>
</template>
<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import LeftMenuNew from "@/components/WFSite/LeftMenuNew";
import SessionTimer from "@/components/WFSite/SessionTimer";

export default {
  mixins: [baseViewMixin, currentUserMixin],
  components: {
    LeftMenuNew,
    SessionTimer,
  },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    menu() {
      this.drawer = !this.drawer;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style lang="scss" scoped></style>
