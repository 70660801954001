<template>
  <div>
    <TopMenu />

    <v-dialog v-model="showDialogSelectionUserType" max-width="350" persistent>
      <v-card>
        <v-card-title>Tipo de Persona</v-card-title>
        <div class="pl-5 pr-5 pb-5">
          <v-radio-group v-model="formFields.userType">
            <v-radio
              label="Persona Natural"
              :value="allConstants.security.userTypes.person"
            ></v-radio>
            <v-radio
              label="Persona Jurídica"
              :value="allConstants.security.userTypes.business"
            ></v-radio>
          </v-radio-group>
          <v-btn class="mr-3" @click="restart">Cancelar</v-btn>
          <v-btn color="primary" @click="endCollectUserType">Continuar</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <v-container fluid>
      <v-card elevation="1" tile class="pa-2">
        <div class="text-h4 mb-10">
          {{
            form.processing ? "Creando el reclamo ..." : "Creación de reclamo"
          }}
        </div>

        <collectorBasicData
          :userType="currentClaimInCreation.userType"
          :showMainButton="true"
          :showBackButton="true"
          :disableBackButton="true"
          :askForPassword="false"
          :stopIfAccountExist="false"
          :preFillIfAccountExist="true"
          :includePhoneVerification="false"
          :preloadData="currentClaimInCreation.collectedBasicData"
          @accountExist="processAccountExist"
          @enter="endCollectBasicData"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectBasicData
          "
        />

        <collectorAddressData
          :userType="currentClaimInCreation.userType"
          :showMainButton="true"
          :showBackButton="true"
          :disableBackButton="false"
          :preloadData="currentClaimInCreation.collectedAddressData"
          @enter="endCollectAddressData"
          @back="currentClaimInCreationGoBackward"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectAddressData
          "
        />

        <collectorInstitutionData
          :userType="currentClaimInCreation.userType"
          :showMainButton="true"
          :showBackButton="true"
          :disableBackButton="false"
          :preloadData="currentClaimInCreation.collectedInstitutionData"
          @enter="endCollectInstitutionData"
          @back="currentClaimInCreationGoBackward"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectInstitutionData
          "
        />

        <collectorProductsData
          :userType="currentClaimInCreation.userType"
          :showMainButton="true"
          :showBackButton="true"
          :disableBackButton="false"
          :preloadData="currentClaimInCreation.collectedProductData"
          @enter="endCollectProductData"
          @back="currentClaimInCreationGoBackward"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectProducts
          "
        />

        <collectorRequestData
          :userType="currentClaimInCreation.userType"
          :showMainButton="true"
          :showBackButton="true"
          :disableBackButton="false"
          :preloadData="currentClaimInCreation.collectedRequestData"
          @enter="endCollectRequestData"
          @back="currentClaimInCreationGoBackward"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectRequestData
          "
        />

        <collectorDocumentsData
          :userType="currentClaimInCreation.userType"
          :showMainButton="true"
          :showBackButton="true"
          :disableBackButton="false"
          :includeTermsAndConditions="true"
          @back="currentClaimInCreationGoBackward"
          @enter="endCollectDocumentsData"
          @abort="restart"
          v-if="
            currentClaimInCreation.currentStep ==
              allConstants.claims.creationSteps.collectDocumentsData
          "
        />

        <v-progress-linear
          indeterminate
          color="cyan"
          v-if="form.processing"
          class="mt-10 mb-10"
        ></v-progress-linear>

        <div class="text-h6" v-if="showResultClaimCreation">
          EL RECLAMO HA SIDO ENVIADO CORRECTAMENTE CON EL FOLIO N°
          {{ claimCreatedNumber }}
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import baseViewMixin from "@/mixins/baseView";
import currentUserMixin from "@/mixins/currentUser";
import currentClaimInCreationMixin from "@/mixins/currentClaimInCreation";

import TopMenu from "@/components/WFSite/TopMenu";

import collectorBasicData from "@/components/PublicSite/User/collectorBasicData";
import collectorAddressData from "@/components/PublicSite/User/collectorAddressData";
import collectorInstitutionData from "@/components/PublicSite/User/collectorInstitutionData";
import collectorProductsData from "@/components/PublicSite/User/collectorProductsData";
import collectorRequestData from "@/components/PublicSite/User/collectorRequestData";
import collectorDocumentsData from "@/components/PublicSite/User/collectorDocumentsData";

export default {
  mixins: [baseViewMixin, currentUserMixin, currentClaimInCreationMixin],
  components: {
    TopMenu,
    collectorBasicData,
    collectorAddressData,
    collectorInstitutionData,
    collectorProductsData,
    collectorRequestData,
    collectorDocumentsData,
  },
  data() {
    return {
      formFields: {
        userType: 0,
      },
      showResultClaimCreation: false,
      claimCreatedNumber: 0,
    };
  },
  computed: {
    showDialogSelectionUserType() {
      return (
        this.currentClaimInCreation.currentStep ==
        this.allConstants.claims.creationSteps.previousStep
      );
    },
  },
  methods: {
    async endCollectUserType() {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.userType = this.formFields.userType;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectBasicData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedBasicData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectAddressData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedAddressData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectInstitutionData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedInstitutionData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectProductData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedProductData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectRequestData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedRequestData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();
    },
    async endCollectDocumentsData(collectedData) {
      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedDocumentsData = collectedData;

      await this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
      await this.currentClaimInCreationGoForeward();

      await this.createClaim();
      this.currentClaimInCreationClear();
    },
    async createClaim() {
      this.initSignalProcessing();

      await this.createOrUpdateClaimantAccount();

      const creationClaimResult = await this.createRealClaim();

      this.claimCreatedNumber = creationClaimResult.data.folio;
      this.showResultClaimCreation = true;

      this.stopSignalProcessing();
    },
    async createOrUpdateClaimantAccount() {
      if (!this.currentClaimInCreation.collectedBasicData.accountId) {
        await this.createAccount();
      } else {
        await this.updateAccount();
      }
    },
    processAccountExist(account) {
      const addressData = {
        region: account.address.region,
        province: account.address.city,
        commune: account.address.commune,
        street: account.address.streetAddress,
        apartment: account.address.apartment,
        building: account.address.building,
      };

      let currentClaimInCreation = this.currentClaimInCreation;
      currentClaimInCreation.collectedAddressData = addressData;

      this.currentClaimInCreationCreateOrUpdate(currentClaimInCreation);
    },
    restart() {
      this.currentClaimInCreationClear();
      this.gotoView("dashboard");
    },
  },
  async beforeMount() {
    this.initSignalLoading();

    const fromPublicSite = false;
    const registeredUser = false;

    await this.startCurrentClaimInCreation(registeredUser, fromPublicSite);

    this.formFields.userType = this.allConstants.security.userTypes.person;

    this.stopSignalLoading();
  },
};
</script>

<style lang="scss" scoped></style>
